import PropTypes from 'prop-types';
import React from 'react';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_ADD_IMAGE_ENCODING } from '@wix/communities-blog-experiments';
import { connect } from '../runtime-context';
import classNames from 'classnames';
import { ImgBackground, Img } from '@wix/communities-image-lib';
import { isSeo } from '../../store/basic-params/basic-params-selectors';

import styles from './post-list-item-image.scss';

export const PostListItemImageDesktop = ({ image, url, isPublic, height, width, isSeo, isImageEncodingEnabled }) => {
  const props = isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true };
  return isSeo ? (
    <div className={classNames(styles.container)} style={{ width, height }} data-hook="post-list-item-image">
      <Img className={styles.imageFit} src={image} />
    </div>
  ) : (
    <div className={classNames(styles.container, styles.desktop)} data-hook="post-list-item-image">
      <ImgBackground className={styles.image} src={image} height={height} width={width} encode={isImageEncodingEnabled} {...props} />
    </div>
  );
};

PostListItemImageDesktop.propTypes = {
  image: PropTypes.object.isRequired,
  url: PropTypes.string,
  isPublic: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  isSeo: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({
  isSeo: isSeo(state),
  isImageEncodingEnabled: isExperimentEnabled(state, EXPERIMENT_ADD_IMAGE_ENCODING),
});

export default connect(mapRuntimeToProps)(PostListItemImageDesktop);
